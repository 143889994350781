@import './../../../../scss/theme-bootstrap';

.cart-item {
  @include pie-clearfix;
  display: block;
  text-align: $ldirection;
  color: $color-black;
  padding: 1em 0;
  border-bottom: 1px solid $color-light-gray;
  &:hover {
    color: $color-black;
  }
  // Layout:
  &__img,
  &__info,
  &__cart-details {
    float: $ldirection;
    display: block;
  }
  &__img {
    width: 30%;
    margin-#{$rdirection}: 4.25%;
    font-size: 12px;
    overflow-wrap: break-word;
  }
  &__info {
    width: 52.75%;
    margin-#{$rdirection}: 4.25%;
    &__header {
      min-height: 50px;
    }
  }
  &__cart-details {
    .cart-block__products & {
      width: 22.25%;
      text-align: $ldirection;
    }
  }

  // Styles:
  &__subheading {
    margin: 0;
    text-transform: none;
  }
  &__info__header {
    a {
      text-decoration: none;
      display: inline-block;
    }
    margin-bottom: 10px;
  }
  &__title {
  }
  &__sku-label,
  &__qty {
    text-transform: uppercase;
    @include t2;
    color: $color-light-black;
  }
  &__price {
    font-size: 1.2em;
    letter-spacing: 0;
    color: $color-black;
  }
  &__shade {
    @include pie-clearfix;
    margin: 10px 0 0;
    &__swatch {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-#{$rdirection}: 10px;
      float: $ldirection;
    }
    &__name {
      display: inline-block;
      margin: 0;
      float: $ldirection;
    }
  }
}

.cart-block {
  &__items {
    &--remove-form {
      a {
        text-transform: lowercase;
      }
    }
    .cart-item {
      .cart-block__products & {
        &__cart-details {
          width: 22.25%;
        }
        &__info__header {
          margin-bottom: 15px;
        }
      }
      &__replenishment {
        margin-top: 5%;
        font-size: 14px;
        text-transform: none;
        &--header {
          font-weight: bold;
        }
      }
    }
  }
}
